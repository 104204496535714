<template>
  <v-container fluid>
    <v-tabs v-model="tab" center-active grow>
      <v-tab v-for="e in tcs" :key="`tab-${e._id}`">{{ e.name }}</v-tab>
      <v-tabs-items v-model="tab">
        <single-table v-for="e in tcs" :key="`table-${e._id}`" :e="e" :df="DisciplineFind" />
      </v-tabs-items>
    </v-tabs>
  </v-container>
</template>

<script>
import gql from 'graphql-tag'
import { deleteQuery, updateQuery } from '@/plugins/graphql'

const query = `
  _id
  type
  ... on StbLtf2024Tc {
    name
    mode
    status
    gender
    publicComment
    disciplines { _discipline order }
    teams {
      club { _id name }
      group
      order
      startdiscipline { _id name }
      athletes {
        person { _id familyName givenName }
      }
      identifiers { name value }
      teamId
    }
  }
`

export default {
  name: 'tables',

  components: {
    SingleTable: () => import('./table')
  },

  props: {
    id: {
      type: String,
      required: true
    }
  },

  data: () => ({
    EventFind: [],
    DisciplineFind: [],
    tab: 0
  }),

  computed: {
    tcs () {
      return this.EventFind
        .slice()
        .map(e => ({
          ...e,
          teams: e.teams.map(t => ({
            ...t,
            identifiers: [{ name: 'gymnet.teamId', value: `${t.teamId}` }]
          }))
        }))
        .sort((a, b) => a.name < b.name ? -1 : 1)
    }
  },

  apollo: {
    EventFind: {
      query: gql`
           query($parent: UUID!) { EventFind(parent: $parent) { ${query} }}
      `,
      variables () {
        return {
          parent: this.id
        }
      },
      subscribeToMore: {
        document: gql`
            subscription($parent: UUID!) { EventCreate(parent: $parent) { ${query} }}
        `,
        variables () {
          return {
            parent: this.id
          }
        },
        updateQuery: updateQuery('EventFind', 'EventCreate')
      }
    },
    DisciplineFind: {
      query: gql`query {
        DisciplineFind { _id name shortName regular }
      }`,
      fetchPolicy: 'cache-first',
      subscribeToMore: {
        document: gql`subscription {
          DisciplineCreate { _id name shortName regular }
        }`,
        updateQuery: updateQuery('DisciplineFind', 'DisciplineCreate')
      }
    },
    $subscribe: {
      EventUpdate: {
        query: gql`
            subscription($parent: UUID!) { EventUpdate(parent: $parent) { ${query} }}
        `,
        variables () {
          return {
            parent: this.id
          }
        }
      },
      EventDelete: {
        query: gql`
            subscription($parent: UUID!) { EventDelete(parent: $parent) }
        `,
        variables () {
          return {
            parent: this.id
          }
        },
        result (id) {
          deleteQuery('EventFind', 'EventDelete', this.EventFind, id)
        }
      }
    }
  }
}
</script>

<style scoped>

</style>
